<template>
	<keep-alive include="/">
		<router-view />
	</keep-alive>
</template>
<script>
import apiService from '@/components/apiService.js'
import wx from "@/components/weixinSDK.js"
// import config from '@/components/config.js'
import {  Toast } from "vant";
export default {
	mounted() {
		// 本地没有用户信息且code存在 请求接口获取用户信息
		var code = this.GetQueryString("code")
		var userInfo = apiService.getLocalUserInfo()
		if(userInfo&&userInfo.objectId){
			this.initShare(userInfo)
			return false
		}else if ((!userInfo || !userInfo.objectId) && code) {
			apiService.getUserInfo(code).then(res => {
				if (res.data.code != 1000) {
					console.log(`获取用户信息失败：${code}`)
					Toast.fail(`获取用户信息失败：${code},code=${code}`);
				} else {
					localStorage.setItem("user", JSON.stringify(res.data.userinfo))
					this.initShare(res.data.userinfo)
				}
				//缓存用户信息
				// localStorage.setItem("user", JSON.stringify({
				// 	id: "631dbf954ae63a97d9b2be37",
				// 	openid: "631dbf954ae63a97d9b2be37"
				// }))
			}).catch(res=>{
				this.initShare()
			})
		}else{
			this.initShare()
		}
		
	},
	methods: {
		GetQueryString(name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
			var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
			var context = "";
			if (r != null)
				context = decodeURIComponent(r[2]);
			reg = null;
			r = null;
			return context == null || context == "" || context == "undefined" ? "" : context;
		},
		async initShare(userinfo) {
			var userInfo = userinfo?userinfo:apiService.getLocalUserInfo()
            const shareData = {
                title: '2024北京顺义樱桃采摘旅游季', // 分享标题
                desc: '2024北京顺义樱桃采摘旅游季', // 分享描述
                link: window.location.origin, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                // link: "https://shh5.zytiyuwenhua.com/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                imgUrl: 'https://h5sports2.oss-cn-beijing.aliyuncs.com/shh5/share1.jpg', // 分享图标
                userId: (userInfo && userInfo.objectId) ? userInfo.objectId : null
            }
            wx.wxShare(shareData, () => { }, (res) => {
                Toast.fail(res.errMsg)
            });
        },
	}
	//  async onLaunch(e) {
	// 	console.log("onLaunch", e)
	// 	console.log(document.location.search)
	// 	console.log(document.location.pathname)
	// 	console.log(document.location)
	// 	const pathname = document.location.pathname
	// 	if(pathname == "/pages/authorize") {//微信用户主动授权落地页
	// 		//通过code请求用户信息，覆盖本地storage
	// 		const  code = e.query.code;
	// 		const  state = e.query.state;
	// 		//向服务端获取用户信息
	// 		console.log(`${pathname}:${code},${state}`)
	// 		if(code) {
	// 			await apiService.getUserInfo(code, state);
	// 		}else {
	// 			uni.navigateTo({
	// 				url:'/pages/index'
	// 			})
	// 		}

	// 	}
	// 	console.log('App Launch')
	// },
	// onShow: function() {
	// 	console.log('App Show')
	// },
	// onHide: function() {
	// 	console.log('App Hide')
	// }
}
</script>
<style>
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	min-width: 375px;
}
</style>
