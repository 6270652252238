import {Toast} from "vant"
export default {
	appId: 'wx61cce520119acf87',
	host: 'https://api.zytiyuwenhua.com',
	indexRoute: '/pages/index',
	/**
	 * @param {String} title 提示标题, 默认 发生系统错误！
	 * @param {String} status 提类类型 success, error,none,默认值 error
	 */
	stateToast(title = '发生系统错误!', status = 'error') {
		Toast.fail(title)
	},
	showLoading(title = '加载中...') {
		Toast.fail(title)
	},
	hideLoading() {
		uni.hideLoading();
	},
	initRange(from, to, suffix = null) {
		var arr = [];
		let index = 0;
		for (let i = from; i <= to; i++) {
			if (suffix) {
				arr.push(i + ' ' + suffix)
			} else {
				arr.push(i)
			}

		}
		return arr
	},
}
