import config from "@/components/config.js"
import { Dialog, Toast } from "vant";
import axios from "axios"
// axios.interceptors.response.use(function (response) {
// 	if (response.data.code == 1000) {
// 		return response.data
// 	} else {
// 		showError(response.data.msg)
// 		return Promise.reject(response)
// 	}

// }, function (error) {
// 	showError(error.msg)
// 	return Promise.reject(error)
// });
function guid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0,
			v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}
function showError(title) {
	Toast.fail(title);
}
const apiService = {
	/**
	 * 微信用户主动授权
	 * @param {string} redirectUri = [https://shh5.zytiyuwenhua.com/pages/authorize/authorize] 
	 * @param {string}  = ['STATE'] 
	 * @return {array} [err,res]
	 */
	wxAuthorize(redirectUri = 'https://shh5.zytiyuwenhua.com/', state = 'STATE') {
		redirectUri = encodeURIComponent(redirectUri)
		//https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf0e81c3bee622d60&redirect_uri=http%3A%2F%2Fnba.bluewebgame.com%2Foauth_response.php&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect
		const authorizeUrl =
			`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
		window.location.href = authorizeUrl;
	},
	/**
	 * 用户鉴权，鉴权失败，跳去授权
	 */
	async isAuthorize() {
		const user = this.getLocalUserInfo();
		if (user && user.openid) {
			return true;
		}
		var res = await Dialog.confirm({
			title: '标题',
			message: '此操作需要微信授权登陆，是否继续？',
			confirmButtonText: '去登陆',
			confirmButtonColor: '#01AD56',
			cancelButtonColor: "#9A9A9A",
		}).catch(() => false)
		if (res == "confirm") {
			this.wxAuthorize()
		}
		return false;
	},
	/**
	 * 获取用户信息
	 */
	getLocalUserInfo() {
		const user = localStorage.getItem("user");
		// console.log(`userinfo:${user}`)
		return JSON.parse(user);
	},

	/**
	 * 通过授权 code 从服务端获取用户信息
	 * @param {Object} code 微信授权code
	 */
	getUserInfo(code, state) {
		//向服务端获取用户信息
		return axios({
			url: `${config.host}/api/wx/getUserinfo?code=${code}`,
			method: 'GET'
		})

	},
	getTimes(userId) {
		//向服务端获取用户信息
		return axios({
			url: `${config.host}/api/cherry/getTimes?userId=${userId}`,
			method: 'GET'
		})

	},
	getParkList(code, state) {
		//向服务端获取用户信息
		return axios({
			url: `${config.host}/api/cherry/parkList`,
			method: 'GET'
		})

	},
	/**
	 * 上传文件到OSS
	 * @param {String} dir 默认值 common 目录，不支持传入多级目录
	 * @param {String} filename default guid() 传undefined 以UUID为文件名
	 * @param {dataUrl} filePath tempfilePath
	 * @param {Function} progressUpdate default 上传进度的回调函数
	 */
	async uploadOSS(dir = 'common', filename = (guid() + '.png'), filePath, progressUpdate = null) {
		let [ossTokenErr, ossTokenRes] = await uni.request({
			url: `https://api.max258.com/api/file/oss/token/${dir}`,
			method: 'GET'
		});
		console.log(ossTokenRes)
		return new Promise((result) => {
			const viewUrl = `https://h5sports2.oss-cn-beijing.aliyuncs.com/${dir}/${filename}`;
			const ossToken = ossTokenRes.data.result;
			const formData = { //下面的属性名称 大小写无所谓 都能通过
				'Key': `${dir}/${filename}`, //OSS存储路径/图片名称 => 如：task/test.jpg 别写成 /test.jpg 会有问题（这里不拼接就是会报403）
				'Policy': ossToken.policy, //后端Base64编码格式的授权策略 用于签名
				'OSSAccessKeyId': ossToken.accessid, //OSS控制台获取
				'Success_Action_Status': '200', //让服务端返回200,不然，成功上传图片后阿里默认返回statusCode: 204
				'Signature': ossToken.signature, //服务端返回的签名内容
			}
			console.log(JSON.stringify(formData))
			const uploadTask = uni.uploadFile({
				url: 'https://h5sports2.oss-cn-beijing.aliyuncs.com',
				filePath: filePath, //choostimage 返回的临时图片路径
				fileType: 'image',
				name: 'file',
				formData: formData,
				success: (res) => {
					if (res.statusCode === 200) {
						res.data = viewUrl
					}
					result(res);
				},
			});
			uploadTask.onProgressUpdate((res) => {
				if (progressUpdate) {
					progressUpdate(res);
				}
			});
		});

	},

	/**
	 * 抽奖
	 * {winType:0}
	 */
	async wheelPlay(userId) {
		return axios({
			url: `${config.host}/api/cherry/play?userId=${userId}`,
			headers: {
				'content-type': 'application/x-www-form-urlencoded'
			}
		});
	},

	/**
	 * 领取奖品
	 * @param {Object} data {winId, userId, realname, mobile, address}
	 */
	async getWin(data) {
		return axios({
			url: `${config.host}/api/play/getWin`,
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			data: data
		});
		// const [err, res] = await uni.request({
		// 	url: `${config.host}/api/play/getWin`,
		// 	method: 'POST',
		// 	headers: {
		// 		'content-type': 'application/x-www-form-urlencoded'
		// 	},
		// 	data: data
		// });
		// if (err) {
		// 	showError(`getWin--${err.errMsg}`);
		// }
		// return res;
	},

	/**
	 * 我要报名
	 * @param {JSON} data {userId, realname, gender, mobile, age, group}
	 */
	async enrollExerise(data) {
		console.log(data)
		const [err, res] = await uni.request({
			url: `${config.host}/api/user/enrollExerise`,
			header: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			method: 'POST',
			data: data
		});
		if (err) {
			showError(`enrollExerise--${err.errMsg}`);
		}
		return res;
	},

	/**
	 * 上传成绩
	 * @param {JSON} data {userId, km, time, resultImgUrl}
	 */
	async uploadResult(data) {
		const [err, res] = await uni.request({
			url: `${config.host}/api/user/uploadResult`,
			method: 'POST',
			header: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			data: data
		});
		if (err) {
			showError(`uploadResult--${err.errMsg}`);
		}
		return res;
	},

	/**
	 * 我要打卡
	 * @param {JSON} data {userId, imgUrl}
	 */
	async clockIn(data) {
		const [err, res] = await uni.request({
			url: `${config.host}/api/dk/clockIn`,
			method: 'POST',
			header: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			data: data
		});
		if (err) {
			showError(`clockIn--${err.errMsg}`);
		}
		return res;
	},

	/**
	 * 打卡列表
	 * @param {JSON} data {userId, type 0-最新打卡,1-获赞最多,2-我的打卡, page, pageSize}
	 */
	async clockInList(data) {
		const [err, res] = await uni.request({
			url: `${config.host}/api/dk/getList`,
			method: 'POST',
			header: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			data: data
		});
		if (err) {
			showError(`clockInList--${err.errMsg}`);
		}
		return res;
	},

	/**
	 * 点讚
	 * @param {Object} data {userId, shareId}
	 */
	async likeOrNot(data) {
		return axios({
			url: `${config.host}/api/cherry/up`,
			method: 'GET',
			header: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			params: data
		});
	},
	async shareSuccess(userId) {
		return axios({
			url: `${config.host}/api/play/shareSuccess?userId=${userId}`,
			method: "GET"
		});
	},

	/**
	 * 我的奖品
	 * @param {Object} data {userId, page=0, pageSize=10}
	 */
	myWins(data) {
		return axios({
			url: `${config.host}/api/play/myWins`,
			method: 'GET',
			header: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			params: data
		});
	},

	/**
	 * 通过UserID 获取用户信息
	 * @param {Object} userId
	 */
	async getUserInfoByUserId(userId) {
		const [err, res] = await uni.request({
			url: `${config.host}/api/user/getUserinfo?userId=${userId}`,
			method: 'GET',
		});
		if (err) {
			showError(`getUserInfoByUserId--${err.errMsg}`);
		}
		return res;
	},

	/**
	 * ocr识别
	 * @param {Object} imgUrl
	 */
	async ocr(imgUrl) {
		const [err, res] = await uni.request({
			url: `${config.host}/api/file/ocr`,
			method: 'POST',
			header: {
				'content-type': 'application/x-www-form-urlencoded'
			},
			data: { imgUrl: imgUrl }
		});
		if (err) {
			showError(`ocr--${err.errMsg}`);
		}
		return res;
	}

}

export default apiService;
