import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
Vue.config.productionTip = false
function setFontSize() {
  var screenWidth = window.clientWidth||window.innerWidth;
  var currentFontSize = screenWidth * 100 / 750;
  document.getElementsByTagName('html')[0].style.fontSize = currentFontSize + 'px';
}
setFontSize()
window.addEventListener("resize", setFontSize)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
