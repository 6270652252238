const jssdk = require("jweixin-module"); //引入JSSDK
const random = require('string-random');
import config from '@/components/config.js'
import apiService from "@/components/apiService.js"
import axios from "axios";
import { Toast } from "vant";
// console.log(`config:${JSON.stringify(config)}`)
const nonceStr = () => random(32, {
	numbers: false
}); // 生成签名的随机串,注意这个s是大写
const timestamp = () => parseInt(new Date().getTime() / 1000) + ""; // 生成签名的时间戳,到秒级
const url = location.href.split("#")[0]; // 获取当前页面的url中#前面那部分
//获取微信签名接口所需要的参数
// console.log(`jssdkConfig:${JSON.stringify(jssdkConfig)}`)

const jsApiList = [
	"scanQRCode",
	'updateAppMessageShareData',
	'updateTimelineShareData',
	'onMenuShareTimeline',
	'onMenuShareAppMessage'
]; //需要使用的微信JS接口列表

export default {
	async wxconfig() {
		const jssdkConfig = {
			nonceStr: nonceStr(),
			timestamp: timestamp(),
			url,
		};
		//服务端获取签名
		var res = await axios({
			url: `${config.host}/api/wx/signature`,
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			method: 'POST',
			data: jssdkConfig
		})
		if (res.data.code != 1000) {
			config.stateToast(`from server:${res.data.msg}`)
			return;
		}
		let wxConfigData = {
			debug: false, //正式部署的时候记得把这个关掉
			// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			appId: config.appId, // 必填，公众号的唯一标识
			timestamp:jssdkConfig.timestamp, // 必填，生成签名的时间戳
			nonceStr:jssdkConfig.nonceStr, // 必填，生成签名的随机串,注意这个s是大写
			signature: res.data.signature, // 必填，签名
			jsApiList, // 必填，需要使用的JS接口列表
		};
		// console.log(wxConfigData)
		jssdk.config(wxConfigData);
		return jssdk
	},
	/**
	 * 微信分享（朋友，朋友圈），在引用的vue onReady｜onLoad 方法中使用
	 * @param {JSON} shareData {title, desc, link, imgUrl}
	 * @param {Function} success
	 * @param {Function} fail
	 * @param {JSON} extra {userId}
	 */
	wxShare(shareData, success, fail) {
		this.wxconfig();
		jssdk.ready(function () { //需在用户可能点击分享按钮前就先调用
			//优先使用旧接口，没有旧接口用新的
			var data = {
				title: shareData.title, // 分享标题
				desc: shareData.desc, // 分享描述
				link: shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
				imgUrl: shareData.imgUrl, // 分享图标
			}
			if (jssdk.onMenuShareAppMessage) {
				jssdk.onMenuShareAppMessage({
					...data,
					success: async () => {
						//设置成功
						console.log('updateAppMessageShareData success')
						console.log(JSON.stringify(shareData));
						if (shareData.userId) {
							const shareResult = await apiService.shareSuccess(shareData.userId)
							if (shareResult.data.code == 1000) {
								// Toast.success("分享成功")
							}
						}
					}
				})
			} else {
				jssdk.updateAppMessageShareData(data)
			}
			if (jssdk.onMenuShareTimeline) {
				jssdk.onMenuShareTimeline({
					...data,
					success: async () => {
						//设置成功
						console.log('updateAppMessageShareData success')
						console.log(JSON.stringify(shareData));
						if (shareData.userId) {
							const shareResult = await apiService.shareSuccess(shareData.userId)
							if (shareResult.data.code == 1000) {
								// Toast.success("分享成功")
							}
						}
					}
				})
			} else {
				jssdk.updateTimelineShareData(data)
			}
			//设置分享到朋友圈
			// jssdk.onMenuShareTimeline({
			// 	// jssdk.updateTimelineShareData({
			// 	title: shareData.titie, // 分享标题
			// 	link: shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
			// 	imgUrl: shareData.imgUrl, // 分享图标
			// 	success: async () => {
			// 		//设置成功
			// 		console.log('onMenuShareTimeline success')
			// 		if (shareData.userId) {
			// 			const shareResult = await apiService.shareSuccess(shareData.userId)
			// 			if (shareResult.data.code == 1000) {
			// 				Toast.success("分享成功")
			// 			}
			// 		}
			// 	}
			// })
		});

		jssdk.error(res => {
			console.error("微信jssdk config失败:", res);
			config.stateToast(res.errMsg)
			fail(res);
		});
	},
};
