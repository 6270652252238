import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    meta: {
      title: "2024北京顺义樱桃采摘旅游节",
    },
    component: () => import('../views/index.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    meta: {
      title: "2024北京顺义樱桃采摘旅游节"
    },
    component: () => import('../views/mine.vue')
  },
  {
    path: '/card',
    name: 'Card',
    meta: {
      title: "2024北京顺义樱桃采摘旅游节"
    },
    component: () => import('../views/card.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    meta: {
      title: "2024北京顺义樱桃采摘旅游节"
    },
    component: () => import('../views/detail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
